<template>
  <div>
    <h1 class="page-title mb-4">Ricerche precedenti</h1>
    <b-card class="border-0">

      <div>
        <loader-info v-if="isLoading" />
        <div v-if="!isLoading">
          <div v-if="!hasList">
            <p class="my-4">
              <em>
                <strong>
                  {{listMessageNoElements}}
                </strong>
              </em>
            </p>
          </div>
          <div v-if="hasList">
            <b-row class="mb-2">
              <b-col
                sm="6"
                md="4"
                xl="3"
                v-for="item in list"
                :key="item.companyId"
                class="mb-3"
              >
                <profile-search-data-search-item
                  :data="item"
                />
              </b-col>
            </b-row>
            <div class="ms-auto d-flex align-items-center">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="searchBase.size"
                class="ms-auto"
              >
                <template #first-text><font-awesome-icon icon="fast-backward"></font-awesome-icon></template>
                <template #prev-text><font-awesome-icon icon="step-backward"></font-awesome-icon></template>
                <template #next-text><font-awesome-icon icon="step-forward"></font-awesome-icon></template>
                <template #last-text><font-awesome-icon icon="fast-forward"></font-awesome-icon></template>
              </b-pagination>
              <div class="ms-3">
                <p class="mb-0">
                  ({{rows || 0}})
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { extractErrorMessage, isNotEmpty } from '@/utils/validators';

const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const ProfileSearchDataSearchItem = () => import('./search/ProfileSearchDataSearchItem.vue');

export default {
  name: 'ProfileSearchHistory',
  props: {
    context: String,
  },
  components: {
    LoaderInfo,
    ProfileSearchDataSearchItem,
  },
  data() {
    return {
      searchBase: {
        search: '',
        page: 0,
        size: 12,
        sort: [{ field: 'requestDate', direction: 'DESC' }],
        filters: [],
      },
      submitted: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['subject/isLoading'];
    },
    list() {
      return this.$store.getters['subject/historyList'];
    },
    hasList() {
      return this.$store.getters['subject/historyList'].length > 0;
    },
    currentPage: {
      get() {
        const paging = this.$store.getters['subject/historyPageable'];
        return (paging.number + 1) || 1;
      },
      set(value) {
        // console.log('updateSelf', this.searchBase, value);
        if (this.searchBase.page !== value - 1) {
          this.fetchData(false, value);
        }
      },
    },
    rows() {
      const paging = this.$store.getters['subject/historyPageable'];
      return paging.totalElements;
    },
    listMessageNoElements() {
      return 'Nessuna ricerca effettuata';
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    currentContext() {
      return this.$props.context;
    },
  },
  mounted() {
    this.fetchData(true);
  },
  watch: {
    $route(to, from) {
      // Put your logic here...
      console.log('watch', to.fullPath, from.fullPath);
      if (to.fullPath !== from.fullPath) {
        this.fetchData(true);
      }
    },
  },
  methods: {
    fetchData(reload, nextPage) {
      // console.log('list fectad', this.searchBase, nextPage);
      const search = { ...this.searchBase };
      if (isNotEmpty(nextPage)) {
        const paging = this.$store.getters['subject/historyPageable'];
        if (nextPage > 0 && nextPage <= paging.totalPages) {
          // console.log('nextpage', nextPage);
          search.page = nextPage - 1;
        }
      } else if (reload) search.page = 0;// set page to 0 when switching search type/page
      const entityType = this.isCompanyProfile ? 'company' : 'person';
      const entityId = this.isCompanyProfile
        ? this.$store.getters['subject/currentCompany'].companyId
        : this.$store.getters['subject/currentPerson'].personId;

      // console.log('entity', entityId, entityType);
      const filters = this.currentContext === 'click'
        ? [
          {
            field: 'clickReportAvailable',
            operation: 'EQ',
            value: true,
          },
        ] : [];

      const currentSearch = {
        ...search, entityType, entityId, filters,
      };
      // console.log('currentSearch pre dispatch', currentSearch);
      this.$store.dispatch('subject/searchHistory', {
        search: currentSearch, reset: reload,
      })
        .then(() => {
          // console.log('search', search, reload);
          this.submitted = false;
          this.searchBase.page = search.page;
        },
        (error) => {
          // console.log('error', error);
          this.message = extractErrorMessage(error);
          this.submitted = false;
        });
    },
  },
};
</script>

<style scoped>

</style>
